<template>
  <div class="product-wrap product-instrument">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-instrument"></div>
    <div class="white-wrap">
      <div class="max-center pd-5" >
        <el-row :gutter="40" type="flex" justify="center">
          <el-col :span="8">
            <div class="pdg-box">
              <div class="img">
                <img src="../../assets/img/product/instrument/pic_1.png" alt="超声波液位计">
              </div>
              <div class="txt">超声波液位计</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="pdg-box">
              <div class="img"><img src="../../assets/img/product/instrument/pic_2.png" alt="压力变送器"></div>
              <div class="txt">压力变送器</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="pdg-box">
              <div class="img"><img src="../../assets/img/product/instrument/pic_3.png" alt="电磁流量计"></div>
              <div class="txt">电磁流量计</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="fz-black align-lt">超声波液位计</div>
        <el-row :gutter="30">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="auto-img">
              <img src="../../assets/img/product/instrument/pic_1.png" alt="超声波液位计">
            </div>
          </el-col>
          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="txt-box ">
              超声波液位计是一种带有两个开关点的超声波非接触式开关，用于各种行业中的散装固体、液体和浆料的液位检测；粘性材料的理想选择。
            </div>
          </el-col>
        </el-row>
        <div class="name-title">
          <i class="i-lt"></i><i class="txt">产品详情</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/instrument/pic_4.png" style="margin-left: 5%" alt="型号含义">
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">产品优势</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="txt-box condition-box">
          <p><span>♦</span>简单的两键编程</p>
          <p><span>♦</span>两个用于警报的开关输出</p>
          <p><span>♦</span>法兰适配器</p>
          <p><span>♦</span>具有故障安全功能的电子设备</p>
          <p><span>♦</span>螺纹和卫生配件卡箍过程连接 ，安装灵活</p>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-black align-lt">压力变送器</div>
        <el-row :gutter="30">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="auto-img">
              <img src="../../assets/img/product/instrument/pic_2.png" alt="压力变送器">
            </div>
          </el-col>
          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="txt-box">
              可通过无线方式提供所有工艺测量值以及诊断信息、参数和功能。该设备由一块内部电池供电，功耗极低。由于设计紧凑而坚固，它特别适合直接安装在工厂偏远位置的储罐和管道上，以及安装在移动或旋转设备上，以实现过程监视或资产管理应用。
            </div>
          </el-col>
        </el-row>
        <div class="name-title">
          <i class="i-lt"></i><i class="txt">产品详情</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/instrument/pic_5.png" style="margin-left: 5%" alt="型号含义">
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">产品优势</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="txt-box condition-box">
          <p><span>♦</span>提供有各种压力范围（1.6、10、50、200、320 bar；23、145、725、2900、4641 psi），应用范围广。</p>
          <p><span>♦</span>LCD 显示屏可显示各种功能选项和图标，三个按钮可用来高效进行诊断、通信及安全设置，使用方便，无需额外设备或工具</p>
          <p><span>♦</span> 90 度和 180 度可旋转显示屏和背光功能，维护方便</p>
          <p><span>♦</span>睡眠模式用于高效的电池寿命管理</p>
          <p><span>♦</span>LCD 显示屏上以及网络视图中显示电池状态</p>
          <p><span>♦</span>可通过支持 EDD 的标准工具进行组态，例如，使用 SIMATIC PDM</p>
          <p><span>♦</span>物理 HART 维护端口用于调试</p>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-black align-lt">电磁流量计</div>
        <el-row gutter="30" type="flex" justify="center">
          <el-col :span="10">
            <div class="info-box">
              <div class="img">
                <img src="../../assets/img/product/instrument/pic_3.png" alt="电磁流量计">
              </div>
              <h6>传感器</h6>
              <div class="txt-box">
                传感器带有取得专利的硬橡胶 NBR 或 Ebonite 及 EPDM衬里结构设计，是一种适合所有水务应用的传感器。
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="info-box">
              <div class="img"><img src="../../assets/img/product/instrument/pic_2.png" alt="电磁流量计"></div>
              <h6>发射机</h6>
              <div class="txt-box">
                发射机是基于微处理器的变送器，可在条件十分恶劣的工业环境中使用。
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="name-title">
          <i class="i-lt"></i><i class="txt">传感器详情</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="left-img">
          <img src="../../assets/img/product/instrument/pic_7.png" style="margin-left: 5%" alt="传感器详情">
        </div>
        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">传感器优势</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="txt-box condition-box">
          <p><span>♦</span>能进行“向上调零”和“向下调零”安装</p>
          <p><span>♦</span>衬里特别适合需要特殊认证的引用水应用</p>
          <p><span>♦</span>提高漏水检测的小流量测量精度（NBR 或 EPDM 衬层）</p>
          <p><span>♦</span>适合地下安装和永久性浸没 (IP68) 或水下安装</p>
          <p><span>♦</span>集成接地电极 – 对于很多应用，无需使用接地环</p>
          <p><span>♦</span>交货时间短</p>
        </div>

        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">发射机详情</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>

        <div class="left-img">
          <img src="../../assets/img/product/instrument/pic_8.png" style="margin-left: 5%" alt="发射机详情">
        </div>

        <div class="name-title mt-5">
          <i class="i-lt"></i><i class="txt">发射机优势</i><i class="i-rt">
          <div class="line"></div>
        </i>
        </div>
        <div class="txt-box condition-box">
          <p><span>♦</span>简单的两键编程</p>
          <p><span>♦</span>两个用于警报的开关输出</p>
          <p><span>♦</span>法兰适配器</p>
          <p><span>♦</span>具有故障安全功能的电子设备</p>
          <p><span>♦</span>螺纹和卫生配件卡箍过程连接 ，安装灵活</p>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="auto-img" style="position: relative">
          <img src="../../assets/img/product/instrument/pic_9.jpg" alt="更多仪器仪表请联系我们！">
          <span class="more-txt" @click="handleShow">更多仪器仪表请联系我们！</span>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '仪器仪表-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控仪器仪表-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-instrument {
  .banner-instrument {
    background: url("../../assets/img/product/instrument/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }

  .pdg-box {
    width: 100%;
    height: auto;
    position: relative;
    border: 2px solid #D9D9D9;
    box-sizing: border-box;

    .img {
      width: 90%;
      margin: 0 auto;
      height: auto;
      padding: 15% 0 25%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .txt {
      background: #F0F0F0;
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #595959;
      position: absolute;
      bottom: 0
    }
  }

  .pdg-box:hover {
    border: 2px solid #1890FF;

    .img {
      transform: scale(1.1);
    }

    .txt {
      background: #1890FF;
      color: #FFFFFF;
    }
  }

  .condition-box {
    span {
      color: #1890FF;
      padding: 0 2px;
    }
  }

  .sm-txt {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
  }

  .big-txt {
    font-size: 48px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
    line-height: 1.8;
  }

  .info-box {
    background-color: #ffffff;
    padding: 2% 5% 8%;

    img {
      max-width: 100%;
      height: auto;
    }

    h6 {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #595959;
      line-height: 2;
    }
  }

  .more-txt {
    padding: 2% 5%;
    background: #1890FF;
    color: #ffffff;
    position: absolute;
    right: 15%;
    top: 25%;
  }

}

@media (max-width: 749px) {
  .product-instrument {
    .pdg-box {
      .img {
        padding: 10% 0 25%;
      }
      .txt {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }
    }
  }
}

</style>
